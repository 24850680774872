import { ErrorHandler, Injectable } from "@angular/core";
import { MonitoringService } from "./monitoring.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  private loggingService: MonitoringService = new MonitoringService();
  constructor() {
    super();
  }

  handleError(error: Error) {
    console.error(error);
    this.loggingService.logException(error); // Manually log exception
  }
}
