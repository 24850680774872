import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocService {

  constructor(
    private http: HttpClient
  ) { }
  getPrivacy(){
    return this.http.get('multiro_assets/privacy/MultiroPrivacyPolicy.html',{responseType: 'text' });
  }
}
