<div class="member">
  <div class="member__row-item">
    <p class="member__txt member__txt-break" style="font-weight: bold; margin-bottom: 10px">
      {{displayName}}
    </p>
    <p class="member__row-item member__txt member__txt-break">
      {{email}}
    </p>
  </div>
  <div class="member__row-item member__border-line">

  </div>
  <div class="member__row-item member__agree-receive-email">
    <p>
      {{"SIGNUPDIALOG.AGREERECEIEVE"|translate}}
    </p>
    <!-- <p class="member__agree-receive-email__littleText">{{"SIGNUPDIALOG.OPTIONAL"|translate}}</p> -->
    <mat-slide-toggle [checked]="profile?.optIn" (change)="onOptIn($event)" color="accent">
      {{profile?.optIn?'Yes':'No'}}
    </mat-slide-toggle>
  </div>
  <div class="member__row-item">
    <span *ngIf="currentLang()==='en'">{{"SIGNUPDIALOG.SEE"|translate}}</span>
    <a (click)="pageBehavoirService.openPrivacy()" class="member__policy">
      {{"SIGNUPDIALOG.AGREETHESE.POLICY"|translate}}
    </a>
  </div>
  <div class="member__bottom-btn">
    <button (click)="onLogoutClick()" class="member__btn">{{"USERPROFILEPANEL.SIGNOUT"| translate}}</button>
  </div>
</div>
