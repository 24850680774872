import { Injectable } from '@angular/core';
import { MessageDisplayService } from 'projects/multitrim/src/app/services/message-display.service';
import { IMessageItem } from '../models/IMessageItem';
import { GATrackerService } from './ga-tracker.service';
import { MessageService } from './message.service';


export enum GA_LoginMethod {
  mail = 'Mail-login',
  google = 'Google-login',
  microsoft = 'Microsoft-login',
  apple = 'Apple-login'
}


@Injectable({
  providedIn: 'root'
})
export class EventTrackingService {


  constructor(
    private gaTracker: GATrackerService,
    private message: MessageService,
    private messageDisplay: MessageDisplayService,
  ) {
  }

  trackPageView(pageTitle: string) {
    this.gaTracker.PageView_Send(pageTitle);
  }

  trackEvent(Catagory: string, Action: string, Label: string) {
    this.gaTracker.Event_Send(Catagory, Action, Label);

    const r = this.message.trackEvent(Label);
    r.forEach((m: IMessageItem) => {
      if (!this.messageDisplay.ms._messageDatabase.messageTriggered.has(m.Id)) {
        this.messageDisplay.ms._messageDatabase.messageTriggered.add(m.Id);
        if (this.message.isVisible(m))
          this.messageDisplay.showMessage(m);
      }
    });
  }

}
