import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/uxshared/src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imgSrc = '../../multiro_assets/imgs/mail_light.svg';
  dmgFile = 'MultiroCutter-1.1.1422.dmg';
  dmgUrl = `${environment.downloadBaseUrl}/${this.dmgFile}`;
  constructor() { }

  ngOnInit(): void {
  }

  goMultiroWeb() {
    window.scrollTo(0, 0);
  }

  sendMail() {
    window.location.href = "mailto:support@multiro.io";
  }

  goWebVersion() {
    // window.location.href = `${environment.multiroUrl}/cutter`;
    window.open( `${environment.cutterWeb}`);
  }

  goUWPVersion() {
    window.open('https://www.microsoft.com/store/apps/9NZC1XJCNB76', 'uwp_app_download_window');
  }

  goDownload(){
    console.log('goDownload');
    document.getElementById("download").scrollIntoView({behavior: 'smooth'});
  }
}
