<div class="dialog" *ngIf="version == ''">

  <div class="warningBlock" *ngIf="showWarning === true">
    <img src="multitrim_assets/images/popDialog/warning.svg">
    <p>{{'WARNINGDIALOG.WARNING' | translate}}</p>
  </div>

  <div [ngClass]="needHoverHint?'unsupportContain': btnNumber === 1 ? 'textandhover-center' : 'textandhover'">
    <div [ngClass]="needHoverHint?'unsupportContain__textBlock':'textBlock'">
      <div
        [ngClass]="btnNumber === 1 ? needHoverHint?'unsupportContain__textBlock__text': 'mainContainSingleBtn': needCheckBox || isSettings ? 'mainContainWithCheckBox' :  goToStore ? 'mainContainMultipleBtn-GotoStore' : 'mainContainMultipleBtn'">
        {{title | translate}}</div>
    </div>

    <div class="fastEncodeHint" *ngIf="needHoverHint === true">
      <img src="multitrim_assets/images/appBtns/fastEncodeHint.svg"
        style="width: 23px; height: 23px; padding: 0px 0px;">
      <div class="fastEncodeHover">
        <span class="fastEncodeHover__title">{{'INFO_HINT.SUP_VIDEO_FORMAT' | translate}}</span>
        <ul>
          <li>
            <div class="fastEncodeHover__li" [ngStyle]="{'margin-top': pssInstance.isSafari ? '-20px' : 'auto'}">
              {{'INFO_HINT.FILE_TYPE' | translate}}</div>
          </li>
          <li>
            <div class="fastEncodeHover__li" [ngStyle]="{'margin-top': pssInstance.isSafari ? '-20px' : 'auto'}">
              {{'INFO_HINT.VIDEO_CODEC' | translate}}</div>
          </li>
          <li>
            <div class="fastEncodeHover__li" [ngStyle]="{'margin-top': pssInstance.isSafari ? '-20px' : 'auto'}">
              {{'INFO_HINT.AUDIO_CODEC' | translate}}</div>
          </li>
        </ul>
        <span class="fastEncodeHover__title">{{'INFO_HINT.MAX_FILE_SIZE' | translate}}</span>
        <ul>
          <li>
            <div class="fastEncodeHover__li" [ngStyle]="{'margin-top': pssInstance.isSafari ? '-20px' : 'auto'}">
              {{'INFO_HINT.WEB_VERSION' | translate}}</div>
          </li>
          <li>
            <div class="fastEncodeHover__li" [ngStyle]="{'margin-top': pssInstance.isSafari ? '-20px' : 'auto'}">
              {{'INFO_HINT.INSTALLED_VERSION' | translate}}</div>
          </li>
        </ul>
        <div><span class="fastEncodeHover__title">{{'INFO_HINT.MAX_VIDEO_LENGTH' | translate}}</span></div>
        <div><span class="fastEncodeHover__title">{{'INFO_HINT.VIDEO_RESOLUTION' | translate}}</span></div>
        <!-- {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_1'|translate}}<br>
        {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_2'|translate}}<br>
        {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_3'|translate}}<br>
        {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_4'|translate}}<br>
        {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_5'|translate}}<br>
        {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_6'|translate}}<br>
        {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_7'|translate}}<br>
        {{'HOVERHINTTEXT.HOVER_WRONGVIDEOFORMAT_8'|translate}} -->
      </div>
    </div>
  </div>

  <div class="toggleBlock" *ngIf="isSettings === true">
    <div class="toggleBlock__container toggleBlock__fast-encode">
      <input type="checkbox" id="settings_enableFE" [checked]="usInstance.enableFE">
      <p>{{'DIALOGCONTAINS.USEFASTENCODING'|translate}}</p>

      <div class="toggleBlock__fastEncodeHint">
        <img src="multitrim_assets/images/appBtns/fastEncodeHint.svg" draggable="false">
        <div class="toggleBlock__fastEncodeHint__fastEncodeHover">
          <span class="toggleBlock__fastEncodeHint__fastEncodeHover__text">{{'INFO_HINT.FAST_ENCODE_HINT_TITLE' |
            translate}}</span>
          <ul>
            <li>
              <div>
                <span class="toggleBlock__fastEncodeHint__fastEncodeHover__text">{{'INFO_HINT.FAST_ENCODE_HINT_Ill_1' |
                  translate}}</span>
              </div>
            </li>
            <li>
              <div>
                <span class="toggleBlock__fastEncodeHint__fastEncodeHover__text">{{'INFO_HINT.FAST_ENCODE_HINT_Ill_2' |
                translate}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="textBlock-Export" [style.display]="isSettings ? '' : 'none'">
    <div class="textBlock-Export__content">
      {{'DIALOGCONTAINS.USEFASTENCODING_DETAIL' | translate}}
    </div>
  </div>
  <div class="toggleBlock" *ngIf="isSettings === true">
    <div class="toggleBlock__container toggleBlock__fast-encode">
      <input type="checkbox" id="settings_noAudio" [checked]="usInstance.noAudio">
      <p>{{'DIALOGCONTAINS.EXCLUDEAUDIO'|translate}}</p>
    </div>
  </div>

  <div class="filePath" *ngIf="path !== ''">
    {{path | translate}}
  </div>

  <div class="btnSingleBtnBlock" *ngIf="btnNumber === 1">
    <button class="btn_SingleSlient" type="button" (click)="closeDialog(false)">
      {{silentBtnTitle | translate}}
    </button>
  </div>

  <div [ngClass]="isCancel === true ? 'isCancelbtnBlock' : 'btnBlock'" *ngIf="btnNumber === 2">
    <button [ngClass]="showWarning === true || isCancel === true ? 'emptyBtn_style':'btn_slient'" type="button"
      (click)="closeDialog(false)">
      {{silentBtnTitle | translate}}
    </button>

    <button *ngIf="actionBtnTitle !== ''"
      [ngClass]="showWarning === true || isCancel === true? 'emptyBtn_style':'btn_action'" type="button"
      (click)="closeDialog(true)">
      {{actionBtnTitle | translate}}
    </button>
  </div>

</div>

<div class="about" *ngIf="version !== ''">
  <div class="about__title">
    <img src="multitrim_assets/images/appIcons/Multitrim_About_LOGO.svg" draggable="false">
    <span>{{'DIALOGCONTAINS.MULTIROCUTTER' | translate}}</span>
    <div class="about__title__closeBtn" (click)="closeDialog(true)">
      <!-- <img *ngIf="!showHideCloseBtn" src="multitrim_assets/images/sign-page/sign_close.svg" draggable="false">
      <img *ngIf="showHideCloseBtn" src="multitrim_assets/images/popDialog/closeBtn_mousedown.svg" draggable="false"> -->
    </div>
  </div>
  <div class="about__container">
    <div class="about__container__version">{{MultitrimCutter | translate}}{{version}}</div>
    <div class="about__container__copyRight">{{'DIALOGCONTAINS.COPYRIGHT' | translate}}</div>
  </div>
  <div class="about__footer">
    <div class="about__footer__moreInfo"
      [ngStyle]="{'letter-spacing': pssInstance.isWebApp && pssInstance.isMacOS ? '-0.4px' : 'normal'}">
      <span>{{'DIALOGCONTAINS.ABOUTMOREINFO_1' | translate}}</span>
      <span class="about__footer__link" (click)="openLegalDialog()">{{'DIALOGCONTAINS.LEGALNOTICE' | translate}}</span>
      <span>{{'DIALOGCONTAINS.COMMA' | translate}}</span>
      <span class="about__footer__link" (click)="onTermOfUse()">{{'DIALOGCONTAINS.TERMSOFUSE' | translate}}</span>
      <span>{{'DIALOGCONTAINS.ABOUTMOREINFO_2' | translate}}</span>
      <span class="about__footer__link" (click)="onPrivacy()">{{'DIALOGCONTAINS.PRIVACYPOLICY' | translate}}</span>
      <span>{{'DIALOGCONTAINS.ABOUTMOREINFO_3' | translate}}</span>
    </div>
  </div>
</div>
