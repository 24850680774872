import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'projects/uxshared/src/environments/environment';
import { PlatformServiceService as pss } from 'projects/uxshared/src/lib/services/platform-service.service';
import { AuthService } from '../../service/auth.service';
import { LanguageService } from 'uxshared';

@Component({
  selector: 'lib-user-setting-popup',
  templateUrl: './user-setting-popup.component.html',
  styleUrls: ['./user-setting-popup.component.scss']
})
export class UserSettingPopupComponent implements OnInit {
  settingForm: FormGroup;
  profile;
  constructor(
    public authService: AuthService,
    public dialogRef: MatDialogRef<UserSettingPopupComponent>,
    private languageService: LanguageService
  ) {
    this.settingForm = new FormGroup({
      optIn: new FormControl(true)
    });
    this.authService.onceGetUserProfile().subscribe((res)=> {
      // this.settingForm.get('optIn').setValue(res.optIn);
      this.profile = {
        id: res.id
      };
      // console.log(this.profile)
    });
  }
  ngOnInit(): void {

  }
  close(){
    this.profile = Object.assign(this.profile, this.settingForm.value);
    this.authService.updateUserProfile(this.profile).subscribe((()=>{
      this.authService.setUserProfile();
    }));
    this.dialogRef.close();
  }
  onOptIn(e){
    // this.profile = Object.assign(this.profile, this.settingForm.value);
    // this.authService.updateUserProfile(this.profile).subscribe();
  }
  onPrivacy() {
    var url = `${environment.multiroUrl}/privacy`;
    if (pss.instance.isDesktopApp) {
      const shell = window.require('electron').shell;
      shell.openExternal(url)
    } else {
      window.open(url);
    }
  }
  currentLang() {
    return this.languageService.savedLanguage;
  }
}
