import { Inject, Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { LanguageService, TutorialMessage, TutorialPageComponent } from 'uxshared';
import { ProjectService as ps } from './project.service';
import { GlobalObjectService, GlobalObjectService as gos } from './global-object.service';
import { MessageComponentService, MessageService } from 'projects/uxshared/src/lib/services/message.service';
import { SnotifyToast } from 'projects/uxshared/src/lib/models';
import { SnotifyService } from 'projects/uxshared/src/lib/services';
import { SnotifyPosition } from 'projects/uxshared/src/lib/enums';
import { IMessageItem } from 'projects/uxshared/src/lib/models/IMessageItem';
import { fromEvent, Subscription } from 'rxjs';
import { PlatformServiceService as pss } from 'uxshared';



@Injectable({
  providedIn: 'root'
})
export class MessageDisplayService {
  static instance: MessageDisplayService;

  showingToast: Map<string, SnotifyToast> = new Map<string, SnotifyToast>();
  subscription: Subscription;

  constructor(
    public ms: MessageService,
    private snotifyService: SnotifyService,
    private gos: GlobalObjectService,
    private messageComponentService: MessageComponentService,
    private ls: LanguageService,
  ) {
    return MessageDisplayService.instance = MessageDisplayService.instance || this;
  }

  getJSON(message: string) {
    try {
      const j = JSON.parse(message);
      return j;
    } catch {

    }
    return null;
  }

  getLocalizedString(message: string) {
    var r = message;
    const currentLang = this.ls.currentLang;
    try {
      const j = JSON.parse(message);
      if (j.hasOwnProperty(currentLang))
        r = j[currentLang];
      else if (j.hasOwnProperty('en'))
        r = j['en'];
      else
        r = j;
    } catch {

    }
    return r;
  }

  showLaunchMessags(): void {
    this.ms.allMsg$.subscribe(() => this.showMessages('launch'));
  }

  showMessages(filter = null, unreadOnly = true) {
    this.ms.allMsg.forEach(msg => {
      if (!this.showingToast.get(msg.Id)
        && (!filter || msg.Type === filter)
        && this.ms.isVisible(msg)
        && (!unreadOnly || !this.ms.isMessageRead(msg.Id))
      ) {
        this.showMessage(msg);
      }
    });
  }

  showMessage(msg: IMessageItem) {
    var toast = null;
    if (msg.Format == 'text') {
      toast = this.snotifyService.info(this.getLocalizedString(msg.Message),
        this.getLocalizedString(msg.Title),
        {
          timeout: 0,
          position: SnotifyPosition.rightTop,
          closeOnClick: false
        });
    } else if (msg.Format == 'ng') {
      toast = this.snotifyService.ng(
        this.messageComponentService.getComponent(msg.Title),
        this.getJSON(msg.Message),
        {
          timeout: 0,
          position: SnotifyPosition.rightTop,
          closeOnClick: false
        });
    } else
      return;

    if (!this.subscription) {
      const body = document.body;
      const mouseDown = fromEvent(body, 'pointerdown', {
        passive: true
      });
      this.subscription = mouseDown.subscribe(e => {
        setTimeout(() => {
          if (this.showingToast.size > 0) {
            this.showingToast.forEach((v, h) => {
              this.snotifyService.remove(v.id);
            });
          }  
        }, 100);
      });
    }

    toast.on('beforeHide', (toast: SnotifyToast) => {
      const k = toast['messageId'];
      this.showingToast.delete(k);
      if (this.showingToast.size == 0) {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
        }
      }
    });
    toast.on('close', (toast: SnotifyToast) => {
      this.ms.markMessageRead(msg.Id);
    });
    toast.on('click', (toast: SnotifyToast) => {
      if (msg.ClickAction.Operator == 'browse') {
        if (pss.instance.isDesktopApp) {
          // console.log('desktop');
          const shell = window.require('electron').shell;
          shell.openExternal(msg.ClickAction.Operand)

        } else {
          // console.log('web');
          window.open(msg.ClickAction.Operand);
        }
      } else if (msg.ClickAction.Operator == 'event') {
        gos.instance.toastClickSubscription.emit(msg.ClickAction.Operand);
        this.snotifyService.remove(toast.id);
      } else if (msg.ClickAction.Operator == 'action') {
        if (msg.ClickAction.Operand == 'close') {
          this.snotifyService.remove(toast.id);
        }
      }
    });

    toast['messageId'] = msg.Id;
    this.showingToast.set(msg.Id, toast);
  }
}
