import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'uxauth';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // throw new Error('Method not implemented.');
    try {
      let token = await this.useToken();
      if (token) {
        return true;
      } else {
        token = route.queryParams["token"];
        const from = route.queryParams["from"];
        const isSuccess = await this.vertifyTokenformQuery(from, token);
        // console.log(isSuccess)
        if (isSuccess) {
          return true;
        } else {
          return this.router.parseUrl('/');
        }
      }
    } catch (error) {
      console.error(error);
      return  this.router.parseUrl('/');
    }
  }
  async vertifyTokenformQuery(from, token): Promise<any>{
    try {
      if (!from && !token) {
        return Promise.reject(false);
      }
      await this.authService.usersModel.saveToken(from, token);
      const vertify = await this.authService.vertifyToken().toPromise();
      if (!vertify){
        return Promise.reject(false);
      }
      return Promise.resolve(true);
    } catch (error) {

    }
  }
  async useToken(): Promise<any> {
    const user = await this.authService.usersModel.getToken();
    return user;
  }
}
