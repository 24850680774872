import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlatformServiceService as pss } from 'uxshared';


@Component({
  selector: 'lib-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})

export class LegalNoticeComponent implements OnInit {

    title = '';
    descContain = '';

    constructor(
        public dialog: MatDialog,
        private dialogRef: MatDialogRef<LegalNoticeComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any
    ) {

    }

    ngOnInit(): void {
        console.log(this.data.title);
        if(this.data.title){
            this.title = this.data.title;
        }
        if(this.data.descContain){
            this.descContain = this.data.descContain;
        }
    }

    closeDialog(){
        this.dialogRef.close();
    }

    openUrl(link: string){
      var url = link;
      if (pss.instance.isDesktopApp) {
        const shell = window.require('electron').shell;
        shell.openExternal(url)
      } else {
        window.open(url);
      }
    }
}
