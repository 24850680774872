import { EventEmitter, Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';
import { VideoPlayerComponent } from '../components/video-player/video-player.component';
import { EditMethods } from '../enums/methodChoice';
import { TrimModes } from '../enums/trimModes';
import * as WaveSurfer from 'wavesurfer.js';

class CallbackWhenReady {
  _callback = [];
  _testCallback;

  constructor(testCallback) {
    this._testCallback = testCallback;
  }

  callWhenReady(callback) {
    this._callback.push(callback);
    this.consumeReadyCallback();
  }

  consumeReadyCallback() {
    if (this._testCallback()) {
      while (this._callback.length > 0) {
        this._callback.pop()();
      }
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class GlobalObjectService {
  static instance: GlobalObjectService;

  @LocalStorage('trimMode', TrimModes.FrameWithAudio)
  public _trimMode: TrimModes;

  _prjReadyCallback: CallbackWhenReady;
  _playerReadyCallback: CallbackWhenReady;
  _player: VideoPlayerComponent;
  _wavesurfer: WaveSurfer;
  methodChoosed = EditMethods.None;
  zoomLevels = [];
  zoomLevel = 0;

  _isBusy = false;
  _opacityLoading = false;
  _progress = 0;
  _outputLength = '';
  fileName = '';
  audioAvailable = true;
  _webmExportDoneOnce = false;

  // _activeFileIsMct = false;

  public fileBeforeOpen: EventEmitter<any> = new EventEmitter();
  public fileAfterOpen: EventEmitter<any> = new EventEmitter();
  public trimModeChanged: EventEmitter<any> = new EventEmitter();
  public loadingIsHidden: EventEmitter<any> = new EventEmitter();
  public pauseVideoIfNeed: EventEmitter<any> = new EventEmitter();




  public enableSave = new EventEmitter();

  public onSaveBtnPress = new EventEmitter();
  public onOpenProjectBtnPress = new EventEmitter();
  public onSaveProjectBtnPress = new EventEmitter();
  public onSaveAsProjectBtnPress = new EventEmitter();
  public onExportBtnPress = new EventEmitter();

  public onSplitBtnPress = new EventEmitter();
  public toastClickSubscription = new EventEmitter();

  public onMacOpenFile = new EventEmitter();

  constructor(
  ) {
    this._prjReadyCallback = new CallbackWhenReady(() => this._player && this._wavesurfer);
    this._playerReadyCallback = new CallbackWhenReady(() => {
      return this._player && this._player.duration > 0;
    });
    return GlobalObjectService.instance = GlobalObjectService.instance || this;
  }

  showLoading(opacityLoading = false): void {
    setTimeout(() => {
      this._isBusy = true;
      this._opacityLoading = opacityLoading;
      this._progress = -1;
      console.log('showLoading-2');
    }, 0);
    console.log('showLoading-1');
  }

  hideLoading(): void {
    setTimeout(() => {
      this._isBusy = false;
      this.loadingIsHidden.emit();
      console.log('hideLoading-2');
    }, 0);
    console.log('hideLoading-1');
  }

  setProgress(progress: number) {
    if (!(this._progress == -1 && progress == 100))
      this._progress = progress;
  }

  // resetPanelifAudioNotAvilable(){
  //   if(this.audioAvailable !== true){
  //     this.trimMode = TrimModes.FrameThumbnails;
  //   }
  // }

  get trimMode(): TrimModes {
    return this._trimMode;
  }

  set trimMode(m: TrimModes) {
    this._trimMode = m;
    this.trimModeChanged.emit();
  }

  get player(): VideoPlayerComponent { return this._player; }

  set player(v: VideoPlayerComponent) {
    this._player = v;
    this._prjReadyCallback.consumeReadyCallback();
    this._playerReadyCallback.consumeReadyCallback();
  }

  get wavesurfer(): WaveSurfer {
    return this._wavesurfer;
  }

  set wavesurfer(v: WaveSurfer) {
    this._wavesurfer = v;
    this._prjReadyCallback.consumeReadyCallback();
  }

  callWhenProjectReady(callback): void {
    this._prjReadyCallback.callWhenReady(callback);
  }

  callWhenPlayerReady(callback): void {
    this._playerReadyCallback.callWhenReady(callback);
  }

}
