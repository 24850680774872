<div class="change-password">
  <form [formGroup]="passwordForm" novalidate>
    <ul class="form form_list">
      <li class="form-row">
        <div>
          <p>Old Password</p>
          <mat-form-field class="change-password__old-password" appearance="outline">
            <input type="password" name="oldPassword" formControlName="oldPassword" matInput>
          </mat-form-field>
        </div>
      </li>
      <li class="form-row">
        <div>
          <p>New Password</p>
          <mat-form-field class="change-password__new-password" appearance="outline">
            <input type="password" name="newPassword" formControlName="newPassword" matInput>
          </mat-form-field>
        </div>
      </li>
      <li class="form-row">
        <div>
          <p>Confirm New Password</p>
          <mat-form-field class="change-password__comfirm-password" appearance="outline">
            <input type="password" name="confirmNewPassword" formControlName="confirmNewPassword" matInput>
          </mat-form-field>
        </div>
      </li>
      <li class="form-row">
        <div class="change-password__save-file">
          <button class="form__btn" (click)="submitForm()" mat-raised-button>Save</button>
        </div>
      </li>
    </ul>
  </form>
</div>
