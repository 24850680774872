import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, FormRule } from 'uxauth';

class BirthYear{
  beingday = 1930;
  endingDay = new Date().getFullYear();
  birthYearData = [];
  constructor() {
    const dayRange = this.endingDay - this.beingday;
    for (let index = 0; index < dayRange; index++) {
      this.beingday++;
      this.birthYearData.push(this.beingday);
    }
  }
  getData(): number[]{
    return this.birthYearData;
  }
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  nameRule = [Validators.required, Validators.pattern(FormRule.Name)];
  passwordRule = [Validators.required, Validators.pattern(FormRule.Password)];
  birthYearData = [];
  profile;
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    const birthYear = new BirthYear();
    this.birthYearData = birthYear.getData();
    this.profileForm = new FormGroup({
      email: new FormControl({
        value: '',
        disabled: true
      }),
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      gender: new FormControl(null),
      birthday: new FormControl(null),
      optIn: new FormControl(true)
    });
    this.authService.getUserProfile().subscribe((res) => {
      this.rewriteProfileForm(res);
    });
  }
  ngOnInit(): void {
    this.authService.setUserProfile();
  }
  deleteAccount(): void {
    const isDelete = confirm('Do you realy want to delete Multiro Account?');
    if (isDelete) {
      this.authService.deleteAccount(this.profile.id).subscribe((res)=>{
        this.router.navigate(['multitrim']);
      });
    }else{

    }
  }
  submitForm(): void{
    try {
      const birthday = this.profileForm.get('birthday').value;
      const dateFormate = {
        birthday: birthday ? new Date(`${birthday}-01-01`) : null
      };
      this.profile = Object.assign(this.profile, this.profileForm.value, dateFormate);
      this.authService.updateUserProfile(this.profile).subscribe();

    } catch (error) {
      console.error(error);
    }
  }
  rewriteProfileForm(res): void{
    try {
      const email = this.profileForm.get('email');
      const firstname = this.profileForm.get('firstname');
      const lastname = this.profileForm.get('lastname');
      const gender = this.profileForm.get('gender');
      const birthday = this.profileForm.get('birthday');
      const optIn = this.profileForm.get('optIn');
      const birthdayFormate = new Date(res.birthday).getFullYear();
      const existsBirthday = res.birthday ? birthdayFormate : null;
      email.setValue(res.email);
      firstname.setValue(res.firstName);
      lastname.setValue(res.lastName);
      gender.setValue(res.gender);
      optIn.setValue(res.optIn);
      birthday.setValue(existsBirthday);
      this.profile = {
        id: res.id,
        email: res.email,
        firstName: res.firstName,
        lastName: res.lastName,
        gender: res.gender,
        optIn: res.optIn,
        birthday: existsBirthday
      };
    } catch (error) {
      console.error(error);
    }
  }
}
