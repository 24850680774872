import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { LocalStorage } from 'ngx-webstorage';
import { PlatformServiceService as pss } from 'uxshared';
import { BehaviorSubject } from 'rxjs';
import { IMessageItem } from '../models/IMessageItem';
import { AuthService, LoginStatus } from 'uxauth';
import { jsonp } from '../utils/json';
var compareVersions = require('compare-versions');

@Injectable({
  providedIn: 'root'
})
export class MessageComponentService {

  components: Map<string, any> = new Map<string, any>();

  hasComponent(name: string): boolean {
    return this.components.has(name);
  }

  getComponent(name: string) {
    if (this.components.has(name))
      return this.components.get(name);
    return null;
  }

  addComponent(name: string, component) {
    this.components.set(name, component);
  }
}

class MessageDatabase extends Dexie {

  @LocalStorage('lastDownload', (new Date(2000, 1, 1)).toISOString())
  public _lastDownload: string;

  messageTable: Dexie.Table<IMessageItem, string>;
  _productId: string;
  _tableName: string;
  allMsg: IMessageItem[] = [];
  allMsg$ = new BehaviorSubject<IMessageItem[]>([]);
  _productVersion: string;
  messageRead: Set<string> = new Set<string>();
  messageTriggered: Set<string> = new Set<string>();
  messageVisibilityTriggered: Set<string> = new Set<string>();

  constructor(
    productId: string,
    postfix: string,
    productVersion: string,
    private environment: any,
    private authService: AuthService,
    private messageComponentService: MessageComponentService,
  ) {
    super(environment.production ? `${productId}${postfix}` : `${productId}${postfix}_stg`);
    this._tableName = environment.production ? `${productId}${postfix}` : `${productId}${postfix}_stg`;
    this._productId = productId;
    this._productVersion = productVersion

    this.downloadMessage();
  }

  isValidMessage(d: IMessageItem) {
    const validFrom = new Date(d.ValidFrom);
    const validTo = new Date(d.ValidTo);
    const now = Date.now();
    if (now > validTo.getTime())
      return false;
    if (d.ForceInvalid) return false;
    if (d.Format != 'text' && d.Format != 'ng') return false;
    if (d.Format == 'ng') {
      if (!this.messageComponentService.hasComponent(d.Title))
        return false;
    }
    if (d.Platform != 'all') {
      if (d.Platform == 'web' && !pss.instance.isWebApp) return false;
      if (d.Platform == 'desktop' && !pss.instance.isDesktopApp) return false;
      if (d.Platform == 'windows' && !pss.instance.isWindowsDesktopApp) return false;
      if (d.Platform == 'mac' && !pss.instance.isMacDesktopApp) return false;
    }

    if (d.Version && d.Version.Operator !== 'na' && d.Version.Operand !== '') {
      if (!compareVersions.compare(this._productVersion, d.Version.Operand, d.Version.Operator))
        return false;
    }

    return true;
  }

  async downloadMessage() {
    const v = {};
    v[this._tableName] = 'Id,Type'
    this.version(1).stores(v);

    this.messageTable = this.table(this._tableName);

    const lastDownload = new Date(this._lastDownload);
    const now = new Date();
    const minDiff = 24 * 60 * 60 * 1000;
    if (now.getTime() - lastDownload.getTime() >= minDiff) {
      jsonp(`${this.environment.messageUrl}/${this._productId}.js`,
        {
          name: 'messageCallback',
          param: 'callback' + Date.now().toString()
        }, async (err, data) => {
          await this.transaction('rw', this.messageTable, async () => {
            data.forEach(async (d) => {
              const valid = this.isValidMessage(d);
              d.ValidFrom = new Date(d.ValidFrom);
              d.ValidTo = new Date(d.ValidTo);
              d.Read = false;
              const coll = this.messageTable.filter(e => e.Id == d.Id);
              if (await coll.count() !== 0) {
                // const firstItem = await coll.first();
                // d.Read = firstItem.Read;
              }
              if (valid)
                this.messageTable.put(d);
              else
                this.messageTable.delete(d.Id);
            });
          });
          this.refreshMessage();
          // this._lastDownload = now.toISOString();
        });
    }
  }

  isVisible(d: IMessageItem): boolean {
    if (d.LoginStatus == 'before' && this.authService.loginStatus != LoginStatus.Logout)
      return false;
    if (d.LoginStatus == 'after' && this.authService.loginStatus != LoginStatus.Login)
      return false;

    if (d.VisibilityTriggers && d.VisibilityTriggers.length > 0) {
      if (!this.messageVisibilityTriggered.has(d.Id))
        return false;
    }

    return true;
  }

  async refreshMessage() {
    const now = Date.now();
    this.allMsg = await this.messageTable.filter(message => message.ValidFrom.getTime() <= now && now <= message.ValidTo.getTime()).toArray();
    this.allMsg$.next(this.allMsg);
  }

  async markMessageRead(mid) {
    const item = this.allMsg.find(item => item.Id == mid);
    if (item) {
      if (!this.messageRead.has(item.Id))
        this.messageRead.add(item.Id);
    }
  }

  get unreadCount() {
    var c = 0;
    this.allMsg.forEach(e => {
      if (this.isVisible(e) && !this.messageRead.has(e.Id))
        c++;
    });
    return c;
  }
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  _messageDatabase: MessageDatabase;
  _eventCountMap = new Map<string, number>();
  _eventTriggerMessageIdMap = new Map<string, IMessageItem[]>();
  _visibilityTriggerMessageIdMap = new Map<string, IMessageItem[]>();

  constructor(
    private authService: AuthService,
    private messageComponentService: MessageComponentService
  ) {
  }

  buildEventTrack() {
    const fn = (triggers, map, msg) => {
      if (triggers) {
        const r = triggers.split(/[\n;]/);
        r.forEach((event) => {
          event = event.trim();
          if (event.length > 0) {
            if ((map.has(event)) == false) {
              map.set(event, []);
            }
            map.get(event).push(msg);
          }
        });
      }
    };
    this.allMsg.forEach((msg) => {
      fn(msg.EventTriggers, this._eventTriggerMessageIdMap, msg);
      fn(msg.VisibilityTriggers, this._visibilityTriggerMessageIdMap, msg);
    });
  }

  trackEvent(event: string) {
    if (!this._eventCountMap.has(event)) {
      this._eventCountMap.set(event, 0);
    }
    const c = this._eventCountMap.get(event) + 1;
    this._eventCountMap.set(event, c);
    const k = `${c}@${event}`;
    const r = [];
    if (this._eventTriggerMessageIdMap.has(k)) {
      this._eventTriggerMessageIdMap.get(k).forEach(v => {
        r.push(v);
      });
    }
    if (this._visibilityTriggerMessageIdMap.has(k)) {
      this._visibilityTriggerMessageIdMap.get(k).forEach(v => {
        if (!this._messageDatabase.messageVisibilityTriggered.has(v.Id))
          this._messageDatabase.messageVisibilityTriggered.add(v.Id);
      });
    }

    return r;
  }

  async downloadMessage(productId: string, postfix: string, productVersion: string, env) {
    this._messageDatabase = new MessageDatabase(productId, postfix, productVersion, env, this.authService, this.messageComponentService);
    this._messageDatabase.allMsg$.subscribe(() => {
      this.buildEventTrack();
    });
  }

  get unreadCount() {
    return this._messageDatabase.unreadCount;
  }

  get allMsg() { return this._messageDatabase.allMsg; }
  get allMsg$() { return this._messageDatabase.allMsg$; }

  isVisible(d: IMessageItem) {
    return this._messageDatabase.isVisible(d);
  }

  markMessageRead(mid) { return this._messageDatabase.markMessageRead(mid); }
  isMessageRead(mid) { return this._messageDatabase.messageRead.has(mid); }
}
