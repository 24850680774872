<div class="main">
  <h2 mat-dialog-title>{{title}}</h2>

  <mat-dialog-content>

    <json-editor [options]="editorOptions" [data]="data" (jsonChange)="saveData($event)" #editor></json-editor>

  </mat-dialog-content>

  <mat-dialog-actions>
      <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" (click)="onSave()">Save</button>
  </mat-dialog-actions>
</div>

