import { AuthService, environment } from "uxauth";
import { Inject, Injectable } from "@angular/core";
import { PlatformServiceService } from "uxshared";
import { AuthBehaviorService } from 'uxauth';

import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { WebBehaviorService } from "./web-behavior.service";
import { BrowserWindow } from "electron";
import { Observable } from "rxjs";
@Injectable()
export class DesktopBehaviorService extends WebBehaviorService implements AuthBehaviorService{
  private shell: Electron.Shell;
  authWindow?: any;
  constructor(
    protected plats: PlatformServiceService,
    protected dialog: MatDialog,
    protected authService: AuthService,
    @Inject(environment) env
  ) {
    super(plats, dialog, authService, env);
    this.shell = plats.es.shell;

  }
  async openSetting(url) {
    this.openSettingPopup();
  }
  async openSettingPage(url): Promise<void>{
    const user = await this.authService.usersModel.getToken();
    const addTokenUrl = `${url}?token=${user.token}&from=${user.from}`;
    this.shell.openExternal(addTokenUrl).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.error(err)
    });
  }
  openExtlogin(url, signPopup): Observable<any> {
    return new Observable(subscriber => {
      const width = signPopup.getBoundingClientRect().width;
      const top = signPopup.getBoundingClientRect().top;
      const left = signPopup.getBoundingClientRect().left;
      this.plats.es.ipcRenderer.invoke('OPEN_EXTLOGIN_PAGE', { url, width, top, left }).then(ipc => {
        subscriber.next(ipc);
      });
    });

  }

  say(): void {
    console.log(this);
    console.log('Im a desktop');
  }
  focusMainWindow(): void {
    const window = this.plats.es.remote.getCurrentWindow();
    window.show();
    window.flashFrame(true);
    window.moveTop();
    window.focus();
  }
}
