<div #container class="container" *ngIf="visible">
  <div class="msgContainer" [style.left.px]='msgX' [style.top.px]='msgY'>
    <div id="drawing" class="drawing">
    </div>
    <div class="content" [style.top.px]='msgTop' [style.width.px]='msgWidth' [style.height.px]='msgHeight'>
      <button mat-raised-button class="btnClose" (click)="hide()">
        <mat-icon class="material-icons">close</mat-icon>
      </button>
      <div class="msg">
        {{text}}
      </div>
      <div class="dontShowAgain">
        <mat-checkbox [checked]="dontShowAgain" (change)="onChanged($event)">Do not show again!</mat-checkbox>
      </div>
      <div class="btnsNav">
        <button mat-raised-button (click)="prev()" [disabled]="!prevEnabled">
          <mat-icon class="material-icons">keyboard_arrow_left</mat-icon>
        </button>
        <button mat-raised-button (click)="next()" [disabled]="!nextEnabled">
          <mat-icon class="material-icons">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </div>

  </div>
</div>
