import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "projects/uxshared/src/environments/environment";
import { PlatformServiceService } from "../platform-service.service";
import { PageBehavoirService } from "./page-behavoir.service";

export class WebBehaviorService implements PageBehavoirService {
  constructor(
    protected plats: PlatformServiceService,
    protected router: Router,
  ){

  }
  openPrivacy(): void {
    var url = `${environment.multiroUrl}/privacy`;
    window.open(url, '_blank');
  }

}
