import { Component, OnInit } from '@angular/core';
import { environment } from 'projects/uxshared/src/environments/environment';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor() {

    (<any>window).goMultiroWeb= this.goMultiroWeb.bind(this);
    (<any>window).sendMail= this.sendMail.bind(this);
    (<any>window).goDownloadSection= this.goDownloadSection.bind(this);
   }

  ngOnInit(): void {

  }


  goMultiroWeb() {
    if(environment.production){
      window.location.href = '/'
    } else {
      window.location.href = '/?answer=ulic'
    }
  }

  sendMail() {
    window.location.href = "mailto:support@multiro.io";
  }

  goDownloadSection(){
    console.log('goDownloadSection');
    if(environment.production){
      window.location.href = '/#download'
    } else {
      window.location.href = '/?answer=ulic#download'
    }
  }
}
