import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService, FormRule } from 'uxauth';
import { passwordMustMatch } from '../../passwordMustMatch';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  passwordForm: FormGroup;
  passwordRule = [Validators.required, Validators.pattern(FormRule.Password)];
  profile;
  constructor(
    private authService: AuthService
  ) {
    this.passwordForm = new FormGroup({
      oldPassword: new FormControl({
        value: '',
        disabled: true
      }),
      newPassword: new FormControl({
        value: '',
        disabled: true
      }, this.passwordRule),
      confirmNewPassword: new FormControl({
        value: '',
        disabled: true
      }, this.passwordRule),
    },
    {
      validators: [
        passwordMustMatch('newPassword', 'confirmNewPassword')
      ]
    });
    this.authService.getUserProfile().subscribe((res) => {
      this.profile = res;
      this.rewritePassworForm(this.profile);
    });
  }

  ngOnInit(): void {
    this.authService.setUserProfile();

  }
  submitForm(): void {
    const newPassword = this.passwordForm.get('newPassword').value;
    const oldPassword = this.passwordForm.get('oldPassword').value;
    const passwordForm = {
      id: this.profile.id,
      oldPassword,
      newPassword
    };
    if (this.passwordForm.status === 'VALID') {
      this.authService.changeUserPassword(passwordForm).subscribe(console.log);
    }
  }
  rewritePassworForm(res){
    const oldPassword = this.passwordForm.get('oldPassword');
    const newPassword = this.passwordForm.get('newPassword');
    const confirmNewPassword = this.passwordForm.get('confirmNewPassword');
    if (res.passwordExist){
      oldPassword.enable();
      oldPassword.setValidators(this.passwordRule);
      newPassword.enable();
      confirmNewPassword.enable();
      oldPassword.updateValueAndValidity();
    } else {

      newPassword.enable();
      confirmNewPassword.enable();
    }
  }
}
