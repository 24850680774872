import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-UXShared',
  template: `
    <p>
      uxshared works!
    </p>
  `,
  styles: [
  ]
})
export class UXSharedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
