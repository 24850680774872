import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { environment } from '../../../environments/environment';
import { PlatformServiceService as pss } from '../../services/platform-service.service';
import { UtilService as us } from '../../services/util.service'

@Component({
  selector: 'lib-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss']
})
export class PopupDialogComponent implements OnInit, AfterViewInit {

  title = '';
  checkBoxTitle = '';
  silentBtnTitle = '';
  actionBtnTitle = '';
  btnNumber = 1;
  needCheckBox = false;
  needHoverHint = false;
  showWarning = false;
  enableFE = false;
  showHint = false;
  goToStore = false;
  isCancel = false;

  MultitrimCutter = '';
  version = '';

  exportContent = '';
  exportNote = '';
  path = '';
  pssInstance = pss.instance;
  usInstance = us.instance;

  noAudio = false;

  isSettings = false;
  settingsContent = '';
  settingsNote = '';

  constructor(
    public dialogRef: MatDialogRef<PopupDialogComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {

  }

  closeDialog(data?: any) {
    if (this.needCheckBox || this.isSettings) {
      if (data) {
        us.instance.enableFE = (<HTMLInputElement>document.getElementById('settings_enableFE')).checked;
        us.instance.noAudio = (<HTMLInputElement>document.getElementById('settings_noAudio')).checked;
        const a = {
          enableFE: us.instance.enableFE,
          noAudio: us.instance.noAudio
        };
        this.dialogRef.close(a);
      } else {
        this.dialogRef.close('');
      }
    } else if (this.isCancel) {
      this.dialogRef.close(!data);
    } else {
      this.dialogRef.close(data);
    }
  }

  ngOnInit(): void {
    // console.log(this.data);
    this.title = this.data.descContain;
    this.silentBtnTitle = this.data.silentBtnTitle;
    if (this.data.actionBtnTitle) {
      this.actionBtnTitle = this.data.actionBtnTitle;
    }
    if (this.data.btnNumber) {
      this.btnNumber = this.data.btnNumber;
    }
    if (this.data.isWarning) {
      this.showWarning = this.data.isWarning;
    }
    if (this.data.isSetting) {
      this.isSettings = true;
      this.settingsContent = this.data.settingsContent;
      this.settingsNote = this.data.settingsNote;
    }
    if (this.data.descContain_version) {
      this.MultitrimCutter = this.data.descContain_MultitrimCutter;
      this.version = this.data.descContain_version;
    }
    if (this.data.needHoverHint) {
      this.needHoverHint = this.data.needHoverHint;
    }
    if (this.data.isGoToStore) {
      this.goToStore = this.data.isGoToStore;
    }
    if (this.data.isCancel) {
      this.isCancel = this.data.isCancel;
    }
    if (this.data.path) {
      this.path = this.data.path;
    }
  }

  ngAfterViewInit() {
    if (this.data.checkBoxTitle) {
      (<HTMLInputElement>document.getElementById('enableFE')).checked = false;
      (<HTMLInputElement>document.getElementById('noAudio')).checked = false;
    }
  }

  openLegalDialog() {
    const d = this.dialog.open(LegalNoticeComponent, {
      width: '540px',
      height: '415px',
      data: {
        title: 'DIALOGCONTAINS.LEGALNOTICE',
        descContain: '',
      }
    });
    d.afterClosed().subscribe(result => {
      if (result) {
        console.log(`result = ${result}`);
      }
    });
  }

  onTermOfUse() {
    var url = `${environment.multiroUrl}/terms`;
    if (pss.instance.isDesktopApp) {
      const shell = window.require('electron').shell;
      shell.openExternal(url)
    } else {
      window.open(url);
    }
  }

  onPrivacy() {
    var url = `${environment.multiroUrl}/privacy`;
    if (pss.instance.isDesktopApp) {
      const shell = window.require('electron').shell;
      shell.openExternal(url)
    } else {
      window.open(url);
    }
  }
}
