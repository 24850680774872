import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, of, throwError } from "rxjs";
import { AuthService } from "uxauth";

@Injectable()
export class TokenAuthHttpInterceptor implements HttpInterceptor {
  accountToken = null;
  constructor(
    private authService: AuthService
  ) {
    this.useToken();
  }
  async useToken(): Promise<void>{
    const user = await this.authService.usersModel.getToken();
    this.accountToken = user?.token;
  }
  async handleByToken(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    await this.useToken();
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.accountToken}`
      }
    });
    return next.handle(authReq).pipe(catchError(err => this.handleAuthError(err))).toPromise();
  }
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleByToken(req, next));
  }
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 401 || err.status === 403) {
        this.authService.logoutUser();
        return of(err.message);
      }
    }
    return throwError(err);
  }
}
