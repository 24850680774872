import { Injectable } from '@angular/core';
import { pseudoRandomBytes } from 'crypto';
import { ElectronService } from 'ngx-electron';
import { AddSegmentCommand } from 'projects/multitrim/src/app/commands/addSegmentCommand';
import { Segment } from 'projects/multitrim/src/app/interfaces/segment';

declare var ddsc: any;
var isFirstSaveProject: boolean = true;
var activeFileIsMct: boolean = false;
function getWindow() {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class PlatformServiceService {
  static instance: PlatformServiceService;
  es: ElectronService = new ElectronService();
  isElectronApp = this.es.isElectronApp;

  constructor() {
    var params = new URLSearchParams(window.location.search);
    var forceElectronApp = params.get('forceElectronApp');
    if (forceElectronApp != null) {
      this.isElectronApp = forceElectronApp == '1';
    }

    return PlatformServiceService.instance = PlatformServiceService.instance || this;
  }
  get isWebApp(): boolean {
    return !this.isElectronApp;
  }

  get isDesktopApp(): boolean {
    return this.isElectronApp;
  }

  get isMacDesktopApp(): boolean {
    return this.es.isElectronApp && this.es.isMacOS;
  }

  get isMacWebApp(): boolean {
    return navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
  }

  get isWindowsDesktopApp(): boolean {
    return this.es.isElectronApp && this.es.isWindows;
  }

  get isMacOS(): boolean {
    return this.es.isMacOS;
  }

  get isWindows(): boolean {
    return this.es.isWindows;
  }

  get isWindowsWeb(): boolean {
    return navigator.userAgent.toUpperCase().indexOf('WINDOWS') >= 0;
  }

  get platformName(): string {
    if (this.isWebApp) return 'WebApp';
    if (this.isMacDesktopApp) return 'MacDesktop';
    if (this.isWindowsDesktopApp) return 'WindowsDesktop';
    return 'Unknown';
  }

  get isMaximized(): boolean {
    if (!this.isDesktopApp) return false;
    return this.es.remote.getCurrentWindow().isMaximized();
  }

  get isFullScreen(): boolean {
    if (!this.isDesktopApp) return false;
    return this.es.remote.getCurrentWindow().isFullScreen();
  }

  get videoFolder(): string {
    if (!this.isDesktopApp) return '';
    return this.es.remote.app.getPath('videos');
  }

  get getProjectFirstSaveState(): boolean {
    return isFirstSaveProject;
  }
  get isSafari(): boolean {
    let match = navigator.userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    return match.join(' ').toUpperCase().indexOf('SAFARI') >= 0;
  }

  get getActiveFileIsMct(): boolean {
    return activeFileIsMct;
  }

  getDesktopModule(m: string): any {
    if (!this.isDesktopApp) {
      console.log(`getDesktopModule(${m}) is available only for desktop version`);
      return;
    }
    return getWindow().require(m);
  }

  showItemInFolder(m: string) {
    if (!this.isDesktopApp) {
      console.log(`showItemInFolder(${m}) is available only for desktop version`);
      return;
    }
    return this.es.remote.shell.showItemInFolder(m);
  }

  setProjectFirstSaveState(tf: boolean) {
    isFirstSaveProject = tf;
  }

  setActiveFileIsMctFile (tf: boolean) {
    activeFileIsMct = tf;
  }

  showOpenDialog() {
    const fs = this.getDesktopModule('fs');
    let win = this.es.remote.getCurrentWindow();
    this.es.remote.dialog.showOpenDialog(win, {
      title: "Open Project",
      filters: [
        { name: 'Multiro Cutter Project (*.mct)', extensions: ['mct'] }
      ],
      properties: ['openFile'],
      buttonLabel: "Open",
      securityScopedBookmarks: true
    })
      .then(result => {
        if (result.bookmarks && result.bookmarks.length) {
          console.log(`[export-popup-dialog] selectFolder() result.bookmarks[0] = ${result.bookmarks[0]}`);
          let bookmarks = result.bookmarks[0];
          ddsc.admin.setSecurityScopedBookmark(bookmarks);
        }
        if (result.canceled) {
          console.log("user close the open dialog!");
        } else {
          fs.readFile(result.filePaths[0], "utf-8", (err, data) => {
            if (err) {
              console.log('Error when open MTT file!');
            }

          })
        }
      })
      .catch(err => {
        console.log(err)
      });

  }

  windowClose() {
    if (!this.isDesktopApp) return;
    this.es.ipcRenderer.send('CLOSE_APP');
  }
  windowMinimize() {
    if (!this.isDesktopApp) return;
    this.es.remote.getCurrentWindow().minimize();
  }
  windowMaximize() {
    if (!this.isDesktopApp) return;
    this.es.remote.getCurrentWindow().maximize();
  }
  windowRestore() {
    if (!this.isDesktopApp) return;
    this.es.remote.getCurrentWindow().restore();
  }
  windowUnmaximize() {
    if (!this.isDesktopApp) return;
    this.es.remote.getCurrentWindow().unmaximize();
  }
  windowFullScreen(tf) {
    if (!this.isDesktopApp) return;
    this.es.remote.getCurrentWindow().setFullScreen(tf);
  }
}
