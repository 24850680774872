import { Size } from './size';
import { Point } from 'transformation-matrix';

export class Rect {
  left: number;
  top: number;
  right: number;
  bottom: number;

  constructor(l = 0, t = 0, r = 0, b = 0) {
    this.left = l;
    this.top = t;
    this.right = r;
    this.bottom = b;
  }

  get width() {
    return this.right - this.left;
  }

  get height() {
    return this.bottom - this.top;
  }

  get size(): Size {
    return {cx: this.width, cy: this.height};
  }

  get LT(): Point {
    return {x: this.left, y: this.top};
  }

  get RT(): Point {
    return {x: this.right, y: this.top};
  }

  get RB(): Point {
    return {x: this.right, y: this.bottom};
  }

  get LB(): Point {
    return {x: this.left, y: this.bottom};
  }

  isPtInRect(pt: Point): boolean {
    return this.left <= pt.x && pt.x < this.right && this.top <= pt.y && pt.y < this.bottom;
  }

  interect(r1: Rect, r2: Rect) {
    this.left = Math.max(r1.left, r2.left);
    this.right = Math.min(r1.right, r2.right);
    this.top = Math.max(r1.top, r2.top);
    this.bottom = Math.min(r1.bottom, r2.bottom);
  }

  deflateRect(l, t, r, b) {
    this.left += l;
    this.top += t;
    this.right -= r;
    this.bottom -= b;
  }
}
