import { LoginStatus, UserFrom, UserStatus } from '../model/Users';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import Dexie from 'dexie';
import 'dexie-observable';
import { ICreateChange, IDatabaseChange, IDeleteChange, IUpdateChange } from 'dexie-observable/api';
import { interval, Observable, of, Subject, throwError } from 'rxjs';
import { switchMap, tap, catchError, retry, delay } from 'rxjs/operators';
import { AuthBehaviorService, environment } from 'uxauth';
import { v4 as uuidv4 } from 'uuid';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie-service';
import { WaitLoginComponent } from '../components/wait-login/wait-login.component';
import { PopupService, PlatformServiceService } from 'uxshared';
import { ConnectionService } from 'ngx-connection-service';
// import { environment as envDetail } from 'projects/uxshared/src/environments/environment';

enum CodeEunm {
  timeout,
  serverError,
  other
}
@Injectable()
export class AuthService {
  apiUrl: string;
  usersModel: UsersModel;
  loginStatus: LoginStatus = LoginStatus.Logout;
  loginWindow: Window;
  subject: Subject<UserSubject>;
  private userMeSubject = new Subject();
  multiroUrl:string;
  hasNetworkConnection: boolean;
  hasInternetAccess: boolean;
  isConnected: boolean;
  constructor(
    private http: HttpClient,

    @Inject(environment) env,
    public dialog: MatDialog,
    private cookieService: CookieService,
    private popup: PopupService,
    private psInstance: PlatformServiceService,
    private connectionService: ConnectionService
  ) {
    this.apiUrl = env.apiUrl;
    this.usersModel = new UsersModel(this.cookieService);
    this.subject = this.usersModel.subject;
    this.multiroUrl = env.multiroUrl;
    // tslint:disable-next-line: deprecation

    if(this.psInstance.isDesktopApp){
      this.connectionService.updateOptions({
        heartbeatUrl:'https://dns.google/'
      })

      this.connectionService.monitor().subscribe( currentState => {
          // console.log(currentState);
          this.hasNetworkConnection = currentState.hasNetworkConnection;
          this.hasInternetAccess = currentState.hasInternetAccess;
          if (this.hasNetworkConnection && this.hasInternetAccess) {
            this.isConnected = true;
          } else {
            this.isConnected = false;
          }
          // console.log(`Connection: ${this.isConnected}`)
        }
      )
    } else {

      this.connectionService.updateOptions({
        enableHeartbeat: false
      })
    }



  }
  vertifyToken(){
    try {
      return this.http.post(`${this.apiUrl}/users/me`, {}).pipe(catchError(err => {
        return this.errorHandle(err);
      }));
    } catch (error) {
      console.error(error);
      return this.errorHandle(error);
    }
  }
  setUserProfile() {
    try {
      this.http.post(`${this.apiUrl}/users/me`, {}).pipe(
        retry(1),
        catchError(err => {
          this.userMeSubject.next(err);
          return throwError(err);
        })
      ).subscribe(
        (res) => {
          this.userMeSubject.next(res);
        });
    } catch (error) {
    }
  }
  getUserProfile(): Observable<any> {
    return this.userMeSubject.pipe();
  }
  onceGetUserProfile(): Observable<any>{
    try {
      return this.http.post(`${this.apiUrl}/users/me`, {}).pipe(
        retry(1),
        catchError(err => {
          return throwError(err);
        })
      );
    } catch (error) {
      return throwError(error);
    }
  }
  updateUserProfile(user): Observable<any> {
    try {
      return this.http.post(`${this.apiUrl}/users/profile`, user).pipe(
        delay(1000),
        catchError(err => this.errorHandle(err))
      );
    } catch (error) {
      return throwError(error);
    }
  }
  changeUserPassword(passwordForm): Observable<any> {
    try {
      return this.http.post(`${this.apiUrl}/users/change_password`, passwordForm).pipe(catchError(err => this.errorHandle(err)));
    } catch (error) {
      return throwError(error);
    }
  }
  loginUser(email, password): Observable<any> {
    try {
      return this.http.post(`${this.apiUrl}/users/authenticate`, {
        Email: email,
        Password: password
      }).pipe(
        switchMap((res: any) => {
          try {
            this.usersModel.saveToken(UserFrom.Password, res.token);
            return of(res);
          } catch (error) {
            return throwError(error);
          }
        }),
        catchError(err => {
          return this.errorHandle(err);
        })
      );
    } catch (error) {
      console.error(error);
      return this.errorHandle(error);
    }
  }
  loginUserByGoogle(authBehavior: AuthBehaviorService, signPopup): Observable<any> {

    if(!this.hasConnection()){
      return this.errorHandle({
        error: {
          message:'DIALOGCONTAINS.SOMETHINGWRONG'
        }
      });
    }

    let uuid = uuidv4();
    const loginWaitRef = authBehavior.openExtlogin(`${this.apiUrl}/users/google_login?id=${uuid}`, signPopup);
    this.loginStatus = LoginStatus.WaitLogin;
    // tslint:disable-next-line: deprecation
    const subscribe = loginWaitRef.subscribe(async (isSuccess) => {
      uuid = null;
      if (await this.usersModel.getToken()) {
        this.loginStatus = LoginStatus.Login;
      } else {
        this.loginStatus = LoginStatus.Logout;
      }
    });
    return this.http.get(`${this.apiUrl}/tokencache/query_token?id=${uuid}`).pipe(
      switchMap((res: any) => {
        try {
          this.usersModel.saveToken(UserFrom.Google, res.token);
          // loginWaitRef.close(true);
          this.loginStatus = LoginStatus.Login;
          // this.setUserProfile();
          subscribe.unsubscribe();
          return of(res);
        } catch (error) {
          return throwError(error);
        }
      }),
      catchError(res => {
        console.error(res);
        uuid = null;
        this.loginStatus = LoginStatus.Logout;
        subscribe.unsubscribe();
        if (res.error.code === CodeEunm.serverError){
          return this.errorHandle(res);
        }
        return throwError(res);
      })
    );
  }

  loginUserByApple(authBehavior: AuthBehaviorService, signPopup): Observable<any> {
    if(!this.hasConnection()){
      return this.errorHandle({
        error: {
          message:'DIALOGCONTAINS.SOMETHINGWRONG'
        }
      });
    }
    let uuid = uuidv4();
    const loginWaitRef = authBehavior.openExtlogin(`${this.apiUrl}/users/apple_login?id=${uuid}`, signPopup);
    this.loginStatus = LoginStatus.WaitLogin;
    const subscribe = loginWaitRef.subscribe(async (isSuccess) => {
      uuid = null;
      if (await this.usersModel.getToken()) {
        this.loginStatus = LoginStatus.Login;
      } else {
        this.loginStatus = LoginStatus.Logout;
      }
    });
    return this.http.get(`${this.apiUrl}/tokencache/query_token?id=${uuid}`).pipe(
      switchMap((res: any) => {
        try {
          this.usersModel.saveToken(UserFrom.Apple, res.token);
          // loginWaitRef.close(true);
          this.loginStatus = LoginStatus.Login;
          subscribe.unsubscribe();
          return of(res);
        } catch (error) {
          return throwError(error);
        }
      }),
      catchError(res => {
        uuid = null;
        this.loginStatus = LoginStatus.Logout;
        // loginWaitRef.close(false);
        subscribe.unsubscribe();
        if (res.error.code === CodeEunm.serverError) {
          return this.errorHandle(res);
        }
        return throwError(res);
      })
    );
  }

  loginUserByMicrosoft(authBehavior: AuthBehaviorService, signPopup): Observable<any> {
    let uuid = uuidv4();
    const loginWaitRef = authBehavior.openExtlogin(`${this.apiUrl}/users/microsoft_login?id=${uuid}`, signPopup);
    this.loginStatus = LoginStatus.WaitLogin;
    const subscribe = loginWaitRef.subscribe(async (isSuccess) => {
      uuid = null;
      if (await this.usersModel.getToken()) {
        this.loginStatus = LoginStatus.Login;
      } else {
        this.loginStatus = LoginStatus.Logout;
      }
    });

    return this.http.get(`${this.apiUrl}/tokencache/query_token?id=${uuid}`).pipe(
      switchMap((res: any) => {
        try {
          this.usersModel.saveToken(UserFrom.Microsoft, res.token);
          // loginWaitRef.close(true);
          this.loginStatus = LoginStatus.Login;
          // this.setUserProfile();
          subscribe.unsubscribe();
          return of(res);
        } catch (error) {
          return throwError(error);
        }
      }),
      catchError(res => {
        uuid = null;
        this.loginStatus = LoginStatus.Logout;
        // loginWaitRef.close(false);
        subscribe.unsubscribe();
        if (res.error.code === CodeEunm.serverError) {
          return this.errorHandle(res);
        }
        return throwError(res);
      })
    );
  }


  hasConnection(){
    if(this.psInstance.isDesktopApp){
      if(!this.isConnected){
        return false;
      } else {
        return true;
      }
    } else {
      console.log(`navigator connection: ${navigator.onLine}`)

      if(!navigator.onLine){
        return false;
      } else {
        return true;
      }
    }
  }

  logoutUser(): any {
    this.usersModel.deleteToken();
  }
  errorHandle(err): Observable<never> {
    // alert(JSON.stringify(err));
    this.popup.openError(err?.error?.message ? err?.error?.message : 'DIALOGCONTAINS.SOMETHINGWRONG');
    return throwError(err);
  }
  openLoginWait(): MatDialogRef<WaitLoginComponent> {
    return this.popup.openLoginWait();
  }
  deleteAccount(id) {
    return this.http.post(`${this.apiUrl}/users/delete`, {})
      .pipe(
        switchMap((res: any) => {
          try {
            this.usersModel.deleteToken();
            alert('delete account success');
          } catch (error) {
            return throwError(error);
          }
          return of(res);
        }),
        catchError(err => this.errorHandle(err))
      );
  }
}
interface UsersItem {
  key: string;
  from: string;
  token?: string;
}
interface UserSubject {
  status: string;
  value: IDatabaseChange;
}
interface SignupForm {
  confirmPassword?: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  userAgree: boolean;
}
class UsersModel extends Dexie {
  public user: Dexie.Table<UsersItem, number>; // id is number in this case
  subject = new Subject<UserSubject>();
  usertoken = 'usertoken';
  constructor(
    private cookieService: CookieService
  ) {
    super('UsersModel');
    this.version(1).stores({
      user: 'key,token'
    });
    this.listenDBChanges();
    this.open();
    this.transaction('rw', this.user, () => { });
  }
  listenDBChanges(): void {
    this.on('changes', (changes) => {
      changes.forEach((change: any) => {
        // console.log(change);
        switch (change.type) {
          case 1: // CREATED
            if (change.key === this.usertoken ){
              if (change.obj.from === UserFrom.Password) {
                this.subject.next({
                  status: UserStatus.UserLogin,
                  value: change
                });
              } else if (change.obj.from === UserFrom.Google ||
                change.obj.from === UserFrom.Microsoft ||
                change.obj.from === UserFrom.Apple) {

                this.subject.next({
                  status: UserStatus.UserLoginByThirdParty,
                  value: change
                });
              }
            }
            break;
          case 2: // UPDATED
            if (change.key === this.usertoken) {
              if (change.obj.from === UserFrom.Password) {
                this.subject.next({
                  status: UserStatus.UserReLogin,
                  value: change
                });
              } else if (change.obj.from === UserFrom.Google ||
                change.obj.from === UserFrom.Microsoft ||
                change.obj.from === UserFrom.Apple) {

                this.subject.next({
                  status: UserStatus.UserReLoginByThirdParty,
                  value: change
                });
              }
            }
            break;
          case 3: // DELETED
            if (change.key === this.usertoken) {
              this.subject.next({
                status: UserStatus.UserLogout,
                value: change
              });
            }
            break;
        }
      });
    });
  }
  saveToken(from, token): Promise<number> {
    return this.user.put({
      key: this.usertoken,
      from,
      token
    });
  }
  deleteToken(): Promise<number> {
    return this.user.where({ key: this.usertoken }).delete();
  }
  async getToken(): Promise<any> {
    const token = await this.user.where({ key: this.usertoken }).toArray();
    return token[0];
  }
  setCacheUser(email, firstname, lastname): void {
    this.cookieService.set('email', email);
    this.cookieService.set('firstname', firstname);
    this.cookieService.set('lastname', lastname);
  }
  getCacheUser(): {
    email,
    firstname,
    lastname
  } {
    const email = this.cookieService.check('email') ? this.cookieService.get('email') : null;
    const firstname = this.cookieService.check('firstname') ? this.cookieService.get('firstname') : null;
    const lastname = this.cookieService.check('lastname') ? this.cookieService.get('lastname') : null;
    return {
      email,
      firstname,
      lastname
    };
  }
}
