import { PlatformServiceService } from "../platform-service.service";
import { PageBehavoirService } from "./page-behavoir.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from "projects/uxshared/src/environments/environment";

export class DesktopBehaviorService implements PageBehavoirService {
  private shell: Electron.Shell;
  constructor(
    protected plats: PlatformServiceService,
    protected router: Router,
  ) {
    this.shell = plats.es.shell;
  }
  openPrivacy(): void {
    var url = `${environment.multiroUrl}/privacy`;
    this.shell.openExternal(url).then((res) => {
      console.log(res)
    }).catch((err) => {
      console.error(err)
    });
  }

}
