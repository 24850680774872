
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHandlerService, PlatformServiceService} from 'uxshared';
import { DesktopBehaviorService } from './service/auth-behavoir/desktop-behavior.service';
import { WebBehaviorService } from './service/auth-behavoir/web-behavior.service';
import { WaitLoginComponent } from './components/wait-login/wait-login.component';
import { CookieService } from 'ngx-cookie-service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'projects/material/material.module';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthService } from './service/auth.service';
import { AuthBehaviorService } from './service/auth-behavoir/auth-behavior.service';
import { ChangePasswordComponent } from './components/password/change-password.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RouterModule } from '@angular/router';
import { AuthGuardService } from './guard/auth-guard.service';
import { UserSettingPopupComponent } from './components/user-setting-popup/user-setting-popup.component';
import { OauthPopupComponent } from './components/oauth-popup/oauth-popup.component';
import { StagGuardService } from './guard/stag-guard.service';


export const environment = new InjectionToken('environment');

@NgModule({
  declarations: [
    ChangePasswordComponent,
    ProfileComponent,
    SignInComponent,
    WaitLoginComponent,
    UserSettingPopupComponent,
    OauthPopupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    TranslateModule,
    RouterModule
  ],
  entryComponents: [
    SignInComponent,
    WaitLoginComponent,
    UserSettingPopupComponent
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  exports: [
    ChangePasswordComponent,
    ProfileComponent,
    SignInComponent,
    WaitLoginComponent,
    UserSettingPopupComponent
  ],
  // schemas:[
  //   CUSTOM_ELEMENTS_SCHEMA
  // ]
})
export class UXAuthModule {
  constructor(@Optional() @SkipSelf() parentModule?: UXAuthModule) {
    if (parentModule) {
      throw new Error(
        'Module is already loaded. Import it in the AppModule only'
      );
    }
  }
  static forRoot(env): ModuleWithProviders<UXAuthModule> {
    return {
      ngModule: UXAuthModule,
      providers: [
        CookieService,
        AuthService,
        AuthGuardService,
        StagGuardService,
        { provide: environment, useValue:  env },
        {
          provide: AuthBehaviorService,
          useFactory: (plats, matDialog, authService) => {
            if (plats.isWebApp) {
              return new WebBehaviorService(plats, matDialog, authService, env);
            } else {
              return new DesktopBehaviorService(plats, matDialog, authService, env);
            }
          },
          deps: [
            PlatformServiceService, MatDialog, AuthService
          ]
        },

      ]
    };
  }
}
