import { Component, OnInit,} from '@angular/core';
import {  Router } from '@angular/router';
import { environment } from 'projects/uxshared/src/environments/environment';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {


  constructor(
    private router: Router
  ) {


    (<any>window).onTermsPage= this.onTermsPage.bind(this);
    (<any>window).onPolicyPage= this.onPolicyPage.bind(this);
    (<any>window).goMultiroWeb= this.goMultiroWeb.bind(this);
    (<any>window).sendMail= this.sendMail.bind(this);
    (<any>window).goDownloadSection= this.goDownloadSection.bind(this);
   }

  ngOnInit(): void {
  }

  onTermsPage(){
    window.scroll(0,0);
    // window.location.reload();
  }

  onPolicyPage(){
    this.router.navigate(['/privacy']);
  }

  goMultiroWeb() {
    if(environment.production){
      window.location.href = '/'
    } else {
      window.location.href = '/?answer=ulic'
    }
  }

  sendMail() {
    window.location.href = "mailto:support@multiro.io";
  }

  goDownloadSection(){
    console.log('goDownloadSection');
    if(environment.production){
      window.location.href = '/#download'
    } else {
      window.location.href = '/?answer=ulic#download'
    }
  }
}
