import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
  selector: 'app-json-editor',
  templateUrl: './json-editor.dialog.component.html',
  styleUrls: ['./json-editor.dialog.component.scss']
})
export class JsonEditorDialogComponent implements OnInit {
  @ViewChild('editor', { static: false }) editor!: JsonEditorComponent;
  public editorOptions: JsonEditorOptions;
  public modifiedData: any;
  public saved = false;
  title = 'JSON Editor';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    const ordered = {};
    const src = this.data;
    Object.keys(src).sort().forEach(function (key) {
      // ordered[key] = src[key];
    });
    data = ordered;
    //console.log(JSON.stringify(ordered));
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
  }

  ngOnInit(): void {
  }

  saveData(data: any) {
    // this.modifiedData = this.editor.get();
    console.log(data);
  }

  onSave() {
    this.modifiedData = this.editor.get();
    Object.keys(this.modifiedData).forEach(key => {
      this.data[key] = this.modifiedData[key];
      console.log(`assign ${key}`);
    });
    Object.keys(this.data).forEach(key => {
      if (!this.modifiedData.hasOwnProperty(key)) {
        delete this.data[key];
        console.log(`delete ${key}`);
      }
    });
  }
}
