<div class="profile">
  <form [formGroup]="profileForm" novalidate>
    <ul class="form form_list">
      <li class="form-row">
        <div>
          <p>Email</p>
          <mat-form-field class="profile__email" appearance="outline">
            <input type="email" name="email" formControlName="email" matInput />
          </mat-form-field>
        </div>
      </li>
      <li class="form-row">
        <div class="profile__first-name">
          <p>First Name</p>
          <mat-form-field appearance="outline">
            <input type="text" name="firstname" formControlName="firstname" matInput />
          </mat-form-field>
        </div>
        <div class="profile__last-name">
          <p>Last Name</p>
          <mat-form-field appearance="outline">
            <input type="text" name="lastname" formControlName="lastname" matInput />
          </mat-form-field>
        </div>
      </li>
      <li class="form-row">
        <div class="profile__gender">
          <p>Gender</p>
          <mat-form-field appearance="outline">
            <mat-select name="gender" formControlName="gender" >
              <mat-option [value]="null"></mat-option>
              <mat-option [value]="'M'">Male</mat-option>
              <mat-option [value]="'F'">Female</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="profile__age">
          <p>Birth Year</p>
          <mat-form-field appearance="outline">
            <mat-select name="birthday" formControlName="birthday">
              <mat-option [value]="null"></mat-option>
              <mat-option *ngFor="let year of this.birthYearData" [value]="year">
                {{year}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </li>
      <!-- <li class="form-row">
      <div>
        <p>Location</p>
        <mat-form-field class="profile__location" appearance="outline">
          <mat-select required>
            <mat-option value="0">Taiwan</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </li>
    <li class="form-row">
      <div>
        <p>Lenguage</p>
        <mat-form-field class="profile__lenguage" appearance="outline">
          <mat-select required>
            <mat-option value="0">English</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </li> -->
      <li class="form-row">
        <mat-checkbox  name="optIn" formControlName="optIn" class="profile__agree">I agree to reveive information about software and events. (Optional)
        </mat-checkbox>
      </li>
      <li class="form-row">
        <div class="profile__delete-account">
          <button (click)="deleteAccount()" class="form__btn" mat-raised-button color="warn">Delete Account</button>
        </div>
        <div class="profile__save-file">
          <button class="form__btn" (click)="submitForm()" type="submit" mat-raised-button>Save</button>
        </div>
      </li>
    </ul>
  </form>
</div>
