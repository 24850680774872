import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class LanguageService {
  language$ = new ReplaySubject<LangChangeEvent>(1);
  translate = this.translateService;
  // 國旗對照
  countryFlagsMap: Map<string, string>;
  currentLang = 'en';

  constructor(
    private translateService: TranslateService,
    private storage: LocalStorageService
  ) {
    this.countryFlagsMap = new Map();
    this.countryFlagsMap.set('en', 'flag-us');
    this.countryFlagsMap.set('zh-tw', 'flag-tw');
  }

  setInitState(): void {
    // 根據使用者的瀏覽器語言設定，如果是中文就顯示中文，否則都顯示英文
    // 繁體/簡體中文代碼都是zh
    const savedLanguage = this.savedLanguage;
    if (savedLanguage !== undefined && savedLanguage !== null) {
      this.setLang(savedLanguage);
    } else {
      const browserLang = this.getBrowserLang();
      this.setLang(browserLang);
    }
  }

  getBrowserLang() {
    const lang_cul = this.translate.getBrowserCultureLang().toLocaleLowerCase();
    for (var v of this.countryFlagsMap.keys()) {
      if (v == lang_cul)
        return lang_cul;
    }
    var lang = this.translate.getBrowserLang().toLocaleLowerCase();
    if (lang == 'zh')
      lang = 'zh-tw';
    for (var v of this.countryFlagsMap.keys()) {
      if (v == lang)
        return lang;
    }
    return 'en';
  }

  setLang(lang: string): void {
    this.translateService.onLangChange.pipe(take(1)).subscribe(result => {
      this.language$.next(result);
      this.currentLang = result.lang;
    });
    this.translateService.use(lang);
  }

  set savedLanguage(lang: string) {
    this.storage.store('SavedLanguage', lang);
  }

  get savedLanguage() {
    return this.storage.retrieve('SavedLanguage');
  }
}
