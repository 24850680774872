import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'uxauth';
import { PageBehavoirService} from '../../services/page-behavoir/page-behavoir.service';
import { PopupService } from '../../services/popup.service';
import { LanguageService } from 'uxshared';

@Component({
  selector: 'lib-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.scss']
})
export class MemberComponent implements OnInit {
  @Input() email;
  @Input() displayName;
  @Output() onSettingPage = new EventEmitter<any>();
  @Output() onLogout = new EventEmitter<any>();
  profile;
  yesOrNo: string = '';
  constructor(
    public authService: AuthService,
    public pageBehavoirService: PageBehavoirService,
    private popup: PopupService,
    private languageService: LanguageService,
  ) {
    this.authService.getUserProfile().subscribe((res) => {
      this.profile = {
        id: res.id,
        optIn: res.optIn
      };

    });
    // console.log(pageBehavoirService)
  }

  ngOnInit(): void {
  }
  onSettingClick(){
    this.onSettingPage.emit();
  }
  onLogoutClick(){
    const popDialog = this.popup.openSignOut()
    popDialog.afterClosed().subscribe(result => {
      if(result){
        this.onLogout.emit();
      }
    });

  }
  onOptIn(e) {
    this.profile = Object.assign(this.profile, { optIn: e.checked});
    this.authService.updateUserProfile(this.profile).subscribe();
    // e.checked ? this.yesOrNo = 'Yes' : this.yesOrNo = 'No';
  }

  currentLang() {
    return this.languageService.savedLanguage;
  }
}
