<div class="dialog">
  <!-- <button class="dialog__close" mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button> -->
  <div class="dialog__content">
    <form  [formGroup]="settingForm" >
      <div class="dialog__row dialog__row_check">
        <!-- <label>{{"SIGNUPDIALOG.AGREERECEIEVE"|translate}}</label> -->
        <mat-checkbox  name="optIn" formControlName="optIn" (change)="onOptIn($event)"></mat-checkbox>
        <span class="dialog__checkbox-txt">
          {{"SIGNUPDIALOG.AGREERECEIEVE"|translate}}
          <span *ngIf="currentLang()==='en'" class="dialog__checkbox-txt-small">
            {{"SIGNUPDIALOG.OPTIONAL"| translate}}
          </span>
        </span>
      </div>
      <div class="dialog__row dialog__row_btn">
        <div class="dialog__privacy-box">
          <span *ngIf="currentLang()==='en'">{{"SIGNUPDIALOG.SEE"|translate}}</span>
          <a (click)="onPrivacy()" class="dialog__privacy-box__privacy">
            {{"SIGNUPDIALOG.AGREETHESE.POLICY"|translate}}
          </a>
        </div>
        <button (click)="close()" mat-button mat-raised-button class="dialog__btn">{{"SIGNUPDIALOG.OK" | translate}}</button>
      </div>
    </form>
  </div>
</div>
