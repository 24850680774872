import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { JsonEditorDialogComponent } from './components/json-editor/json-editor.dialog.component';
import { UXSharedComponent } from './uxshared.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FilePickerDirective } from './directives/file-picker.directive';
import { HttpClientModule } from '@angular/common/http';
import { TutorialPageComponent } from './components/tutorial-page/tutorial-page.component';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HistoryPaletteComponent } from './components/history-palette/history-palette.component';
import { MaterialModule } from 'projects/material/material.module';
import { PopupDialogComponent } from './components/popup-dialog/popup-dialog.component';
import { I18nModule } from './i18n/i18n.module';
import { PopupService } from './services/popup.service';
import { MemberComponent } from './components/member/member.component';
import { LegalNoticeComponent } from './components/popup-dialog/legal-notice/legal-notice.component';
import { PageBehavoirService } from './services/page-behavoir/page-behavoir.service';
import { PlatformServiceService } from './services/platform-service.service';
import { WebBehaviorService } from './services/page-behavoir/web-behavoir.service';
import { DesktopBehaviorService } from './services/page-behavoir/desktop-behavoir.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@NgModule({
  declarations: [
    UXSharedComponent,
    JsonEditorDialogComponent,
    FilePickerDirective,
    TutorialPageComponent,
    HistoryPaletteComponent,
    PopupDialogComponent,
    MemberComponent,
    LegalNoticeComponent
  ],
  imports: [
    CommonModule,
    NoopAnimationsModule,
    MatIconModule,
    MaterialModule,
    NgJsonEditorModule,
    HttpClientModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300
    }),
    NgxWebstorageModule.forRoot(),
    I18nModule
  ],
  entryComponents: [
    PopupDialogComponent
  ],
  exports: [
    UXSharedComponent,
    JsonEditorDialogComponent,
    FilePickerDirective,
    TutorialPageComponent,
    HistoryPaletteComponent,
    PopupDialogComponent,
    MemberComponent,
    LegalNoticeComponent
  ],
  providers: [
    PopupService
  ]
})
export class UXSharedModule {
  constructor(@Optional() @SkipSelf() parentModule?: UXSharedModule) {
    if (parentModule) {
      throw new Error(
        'Module is already loaded. Import it in the AppModule only'
      );
    }
  }
  static forRoot(): ModuleWithProviders<UXSharedModule> {
    return {
      ngModule: UXSharedModule,
      providers:[
        {
          provide: PageBehavoirService,
          useFactory: (plats, route)=>{
            if (plats.isWebApp) {
              return new WebBehaviorService(plats, route);
            }else{
              return new DesktopBehaviorService(plats, route);
            }
          },
          deps: [
            PlatformServiceService, Router
          ]
        }
      ]
    };
  }
}
