import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocService } from '../doc.service';
import {  Router } from '@angular/router';
import { environment } from 'projects/uxshared/src/environments/environment';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacy;
  constructor(
    private sanitizer: DomSanitizer,
    private docService: DocService,
    private router: Router
  ) {
    (<any>window).onTermsPage= this.onTermsPage.bind(this);
    (<any>window).onPolicyPage= this.onPolicyPage.bind(this);
    (<any>window).goMultiroWeb= this.goMultiroWeb.bind(this);
    (<any>window).sendMail= this.sendMail.bind(this);
    (<any>window).goDownloadSection= this.goDownloadSection.bind(this);
  }

  ngOnInit(): void {
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onTermsPage(){
    this.router.navigate(['/terms']);
  }

  onPolicyPage(){
    // this.router.navigate(['/privacy'])
    window.scroll(0,0);
  }

  goMultiroWeb() {
    if(environment.production){
      window.location.href = '/'
    } else {
      window.location.href = '/?answer=ulic'
    }
  }

  sendMail() {
    window.location.href = "mailto:support@multiro.io";
  }

  goDownloadSection(){
    console.log('goDownloadSection');
    if(environment.production){
      window.location.href = '/#download'
    } else {
      window.location.href = '/?answer=ulic#download'
    }
  }

}
