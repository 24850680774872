import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupDialogComponent } from '../components/popup-dialog/popup-dialog.component';
import { ɵELEMENT_PROBE_PROVIDERS__POST_R3__ } from '@angular/platform-browser';
// const { version, channel } = require('../../../../../../package.json');
const { version, channel } = require('../../../../../../workspace/package.json');
@Injectable()
export class PopupService {

  constructor(
    private dialog: MatDialog,
  ){
  }

  openComplete(msg): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      width: '400px',
      minHeight: '174px',
      data: {
        descContain: msg,
        btnNumber: 1,
        silentBtnTitle: 'BTN.OK',
        actionBtnTitle: '',
      },
      disableClose: true,
      autoFocus: false

    });
  }


  openError(err): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      maxWidth: '660px',
      minHeight: '174px',
      data: {
        descContain: err,
        btnNumber: 1,
        silentBtnTitle: 'BTN.OK',
        actionBtnTitle: '',
      },
      disableClose: true,
      autoFocus: false

    });
  }
  openMissingError(err, filePath): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      maxWidth: '660px',
      minHeight: '174px',
      data: {
        descContain: err,
        path: filePath,
        btnNumber: 1,
        silentBtnTitle: 'BTN.OK',
        actionBtnTitle: '',
      },
      disableClose: true,
      autoFocus: false

    });
  }
  openFormatError(err): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      maxWidth: '660px',
      minHeight: '174px',
      data: {
        descContain: err,
        btnNumber: 1,
        needHoverHint: true,
        silentBtnTitle: 'BTN.OK',
        actionBtnTitle: ''
      },
      disableClose: true,
      autoFocus: false

    });
  }
  openFileError(err): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      maxWidth: '660px',
      minHeight: '174px',
      data: {
        descContain: err,
        btnNumber: 1,
        silentBtnTitle: 'BTN.CLOSE',
        actionBtnTitle: '',
      },
      disableClose: true,
      autoFocus: false

    });
  }
  openWarning(warning): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      width: '660px',
      minHeight: '248px',
      data: {
        descContain: warning,
        btnNumber: 2,
        silentBtnTitle: `WARNINGDIALOG.NOBTN`,
        actionBtnTitle: `WARNINGDIALOG.YESBTN`,
        isWarning: true
      },
      disableClose: true,
      autoFocus: false
    });
  }

  openGotoStore(content): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      maxWidth: '700px',
      maxHeight: '229px',
      data: {
        descContain: content,
        btnNumber: 2,
        silentBtnTitle: `BTN.NOTNOW`,
        actionBtnTitle: `BTN.GOTOSTORE`,
        isGoToStore: true
      },
      disableClose: true,
      autoFocus: false,
    });
  }
  openSignInDialog(content): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      width: '600px',
      minHeight: '161px',
      data: {
        descContain: content,
        btnNumber: 2,
        silentBtnTitle: `BTN.NOTNOW`,
        actionBtnTitle: `BTN.SIGNIN`,
      },
      disableClose: true,
      autoFocus: false
    });
  }

  openLoginWait(): MatDialogRef<PopupDialogComponent> {
    return this.dialog.open(PopupDialogComponent, {
      width: '500px',
      height: '200px',
      data: {
        descContain: 'SIGNINDIALOG.WAITUSER',
        btnNumber: 1,
        silentBtnTitle: 'BTN.CANCEL'
      },
      disableClose: true,
      autoFocus: false
    });
  }

  openExistFileOnExportVideo(alert: any): MatDialogRef<PopupDialogComponent> {
    return this.dialog.open(PopupDialogComponent, {
      width: '600px',
      height: '200px',
      data: {
        descContain: alert,
        btnNumber: 2,
        silentBtnTitle: `BTN.NO`,
        actionBtnTitle: `BTN.YES`,
      },
      disableClose: true,
      autoFocus: false
    });
  }

  openCancel():MatDialogRef<PopupDialogComponent> {
    return this.dialog.open(PopupDialogComponent, {
      width: '410px',
      height: '162px',
      data: {
        descContain: `DIALOGCONTAINS.PROCESSCANCEL`,
        btnNumber: 2,
        silentBtnTitle: `BTN.YES`,
        actionBtnTitle: `BTN.NO`,
        isCancel: true,
      },
      position: {
        top: this.currentWindowSize('top', 700),
      },
      disableClose: true,
      autoFocus: false
    });
  }

  closeAllDialog(){
    this.dialog.closeAll();
  }

  openAbout(): MatDialogRef<PopupDialogComponent> {
    return this.dialog.open(PopupDialogComponent, {
      width: '660px',
      minHeight: '276px',
      data: {
        descContain_MultitrimCutter: `DIALOGCONTAINS.MULTIROCUTTERVERSION`,
        descContain_version: version,
      },
      disableClose: false,
      autoFocus: false
    });
  }

  openNoSegmentMatch(msg): MatDialogRef<PopupDialogComponent>{
    return this.dialog.open(PopupDialogComponent, {
      width: '480px',
      minHeight: '174px',
      data: {
        descContain: msg,
        btnNumber: 1,
        silentBtnTitle: 'BTN.OK',
        actionBtnTitle: '',
      },
      disableClose: true,
      autoFocus: false

    });
  }
  openSignOut():MatDialogRef<PopupDialogComponent> {
    return this.dialog.open(PopupDialogComponent, {
      width: '430px',
      minHeight: '161px',
      data: {
        descContain: `DIALOGCONTAINS.SIGNOUT`,
        btnNumber: 2,
        silentBtnTitle: `BTN.YES`,
        actionBtnTitle: `BTN.NO`,
        isCancel: true,
      },
      disableClose: true,
      autoFocus: false
    });
  }

  currentWindowSize(pos: string, val: number): string {
    if(pos === 'top'){
      return ((window.innerHeight - val) / 2).toString() + 'px';
    }
    return '0px'
  }

  openSettings(): MatDialogRef<PopupDialogComponent> {
    return this.dialog.open(PopupDialogComponent, {
      width: '660px',
      maxHeight: '390px',
      data: {
        descContain: `TIPS.SETTINGS`,
        settingcheckBox: `DIALOGCONTAINS.USEFASTENCODING`,
        settingsContent: `DIALOGCONTAINS.EXPORTVIDEOENABLEFASTENCODING_CONTENT`,
        settingsNote: `DIALOGCONTAINS.USEFASTENCODING_DETAIL`,
        btnNumber: 2,
        silentBtnTitle: `BTN.CANCEL`,
        actionBtnTitle: `BTN.OK`,
        isSetting: true,
      },
      disableClose: false,
      autoFocus: false
    });
  }
}
