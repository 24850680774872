import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MultitrimSharedModule } from 'projects/multitrim/src/app/app.module';
import { StagGuardService } from 'projects/uxauth/src/lib/guard/stag-guard.service';
import { SettingComponent } from './account/components/setting/setting.component';
import { HelpComponent } from './help/help.component';
import { HomeComponent } from './home/home.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    component: HomeComponent,
    canActivate: [StagGuardService],
  },
  {
    path: 'terms',
    component: TermsOfUseComponent,
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'help',
    component: HelpComponent,
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy'
    }),
    // MultitrimSharedModule.forRoot(),
    // MulticamSharedModule.forRoot(),
    // ScreenCaptureSharedModule.forRoot()
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
