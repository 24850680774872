export enum UserStatus{
  UserLogin = 'USER_LOGIN',
  UserLogout = 'USER_LOGOUT',
  UserReLogin = 'USER_RE_LOGIN',
  UserLoginByThirdParty = 'USER_LOGIN_BY_THIRD_PARTY',
  UserReLoginByThirdParty = 'USER_RE_LOGIN_BY_THIRD_PARTY'
}
export enum UserFrom {
  Password = 'PASSWORD',
  Google = 'GOOGLE',
  Microsoft = 'MICROSOFT',
  Apple = 'APPLE',
}
export enum LoginStatus{
  Logout = 'LOGOUT',
  WaitLogin = 'WAIT_LOGIN',
  Login = 'LOGIN'
}
