import { HttpClient } from '@angular/common/http';
import { ElementRef, Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  static instance: UtilService;

  enableFE = true;
  noAudio = false;
  _activeFileExt;

  constructor(private http: HttpClient) {
    return UtilService.instance = UtilService.instance || this;
  }

  incUsage(productId: string, page: string) {
    console.log('incUsage');
    this.http.get(`https://ulictekwebappscs.azurewebsites.net/api/hc/inc/${productId}/${page}`)
      .subscribe(v => console.log(v));
  }

  getFileExtName(fileName) {
    var fileExtName = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
    this._activeFileExt = fileExtName;
    return fileExtName;
  }

  toTimeCode(pos: number) {
    const h = Math.floor(pos / 3600);
    const m = Math.floor((pos - h * 3600) / 60);
    const rs = Math.ceil((pos - h * 3600 - m * 60) * 100) / 100;
    const s = Math.floor(rs);
    const s100th = Math.ceil((rs - s) * 100);
    const hs = h < 10 ? `0${h}` : `${h}`;
    const ms = m < 10 ? `0${m}` : `${m}`;
    const ss = s < 10 ? `0${s}` : `${s}`;
    const s100ths = s100th < 10 ? `0${s100th}` : `${s100th}`;
    return `${hs}:${ms}:${ss}.${s100ths}`;
  }
}
