import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { PathArray, SVG } from '@svgdotjs/svg.js'
import { Rect } from '../../primitive';

export class TutorialMessage {
  message: { id: string, text: string }[];
};

@Component({
  selector: 'app-tutorial-page',
  templateUrl: './tutorial-page.component.html',
  styleUrls: ['./tutorial-page.component.scss']
})
export class TutorialPageComponent implements OnInit, AfterViewInit {
  @ViewChild('container', { static: false }) container!: ElementRef;
  visible: boolean = false;

  msgX = 120;
  msgY = 100;
  msgWidth = 400;
  msgHeight = 250;
  round = 10;
  peekWidth = 40;
  peekHeight = 40;
  triangleUp = true;
  dontShowAgain = false;
  hiddenCallback;

  _draw = null;

  currentMsgIdx = 0;
  msg: TutorialMessage = null;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private zone: NgZone,
  ) { }

  ngOnInit(): void {

  }

  onChanged(event) {
    this.dontShowAgain = event.checked;
  }

  get msgTop() {
    return this.triangleUp ? this.peekHeight : 0;
  };

  get text() {
    return this.msg.message[this.currentMsgIdx].text;
  }

  hide() {
    this.visible = false;
    if (this.hiddenCallback)
      this.hiddenCallback(this.dontShowAgain);
  }

  get prevEnabled() {
    return this.currentMsgIdx > 0;
  }

  get nextEnabled() {
    return this.currentMsgIdx < this.msg.message.length - 1;
  }

  goNext(delta) {
    if (this._draw) {
      this._draw.remove();
      this._draw = null;
    }
    setTimeout(() => {
      this.zone.run(() => {
        this.goMsg(this.currentMsgIdx + delta);
      });
    }, 0);
  }

  prev() {
    this.goNext(-1);
  }

  next() {
    this.goNext(1);
  }

  show(msg: TutorialMessage, options: { dontShowAgain: boolean, hiddenCallback: any }) {
    this.visible = true;
    this.currentMsgIdx = 0;
    this.msg = msg;
    this.dontShowAgain = options.dontShowAgain;
    this.hiddenCallback = options.hiddenCallback;
    this.goNext(0);
  }

  goMsg(idx: number) {
    if (idx < 0 || idx > this.msg.message.length - 1)
      return;

    const v = this._document.getElementById(this.msg.message[idx].id);
    if (!v)
      return;

    this.currentMsgIdx = idx;
    const clientrc = this.container.nativeElement.getBoundingClientRect();
    const targetrc = v.getBoundingClientRect();

    const width = this.msgWidth;
    const height = this.msgHeight + this.peekHeight;
    const round = this.round;
    const peekWidth = this.peekWidth;
    const peekHeight = this.peekHeight;

    this.triangleUp = clientrc.bottom > targetrc.bottom + height;
    this.msgX = (targetrc.left + targetrc.right) / 2 - width / 2;
    const oriMsgX = this.msgX;
    if (this.msgX + width >= clientrc.right) this.msgX = clientrc.right - width;
    if (this.msgX < clientrc.left) this.msgX = clientrc.left;
    const msgDX = oriMsgX - this.msgX;

    if (this.triangleUp) {
      this.msgY = targetrc.bottom;
    } else {
      this.msgY = targetrc.top - height;
    }

    this._draw = SVG().addTo('#drawing').size(width, height);

    var top = this.triangleUp ? this.peekHeight : 0;
    var bottom = this.triangleUp ? 0 : this.peekHeight;

    const rc: Rect = new Rect(0, 0, width, height);
    rc.deflateRect(0, top, 0, bottom);

    const paDown: PathArray = new PathArray([
      ['M', rc.left, rc.top + round],
      ['Q', rc.left, rc.top, rc.left + round, rc.top],
      ['H', rc.right - round],
      ['Q', rc.right, rc.top, rc.right, rc.top + round],
      ['V', rc.bottom - round],
      ['Q', rc.right, rc.bottom, rc.right - round, rc.bottom],
      ['H', rc.width * 1 / 2 + peekWidth / 2],
      ['L', rc.width * 1 / 2 + msgDX, rc.bottom + peekHeight],
      ['L', rc.width * 1 / 2 - peekWidth / 2, rc.bottom],
      ['H', rc.left + round],
      ['Q', rc.left, rc.bottom, rc.left, rc.bottom - round],
      ['V', rc.top + round],
    ]);
    const paUp: PathArray = new PathArray([
      ['M', rc.left, rc.top + round],
      ['Q', rc.left, rc.top, rc.left + round, rc.top],

      ['H', rc.width * 1 / 2 - peekWidth / 2],
      ['L', rc.width * 1 / 2 + msgDX, rc.top - peekHeight],
      ['L', rc.width * 1 / 2 + peekWidth / 2, rc.top],

      ['H', rc.right - round],

      ['Q', rc.right, rc.top, rc.right, rc.top + round],
      ['V', rc.bottom - round],
      ['Q', rc.right, rc.bottom, rc.right - round, rc.bottom],
      ['H', rc.left + round],
      ['Q', rc.left, rc.bottom, rc.left, rc.bottom - round],
      ['V', rc.top + round],
    ]);

    this._draw.path(this.triangleUp ? paUp : paDown).attr({ fill: 'gray', stroke: 'black', "stroke-width": '1px' });
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.goMsg(this.currentMsgIdx);
    // }, 100);
  }
}
